// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import 'themes/theme';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$hintly-ui-primary: mat.define-palette(mat.$indigo-palette);
$hintly-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$hintly-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$hintly-ui-theme: mat.define-light-theme((
  color: (
    primary: $hintly-ui-primary,
    accent: $hintly-ui-accent,
    warn: $hintly-ui-warn,
  ),
  typography: mat.define-typography-config(),
  density: 0
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($hintly-ui-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0;
  padding: 0;
  font-family: "VisbyCF", Roboto, sans-serif!important;
}

app-root {
  min-height: 100vh;
  font-family: "VisbyCF", Roboto, "Helvetica Neue", sans-serif;
  background-color: color(grey, base);
  display: flex;
  flex-direction: column;
  position: relative;
  .link {
    color: color(primary, base);
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: color(primary, darker);
    }
  }

  .input-group { padding: 5px 10px; }
  input.is-invalid { border: 1px solid color(red, base); }
  .invalid-feedback { color: color(red, base); }
  .mdc-label {
    font-family: "VisbyCF", Roboto, "Helvetica Neue", sans-serif;
    color: color(black, secondary);
    font-weight: 500;
  }
  .hintly-checkbox {
    .mdc-label {
      font-size: 13px;
      white-space: normal;
      padding: 5px 0;
      font-family: "VisbyCF", Roboto, "Helvetica Neue", sans-serif;
      color: color(black, secondary);
      font-weight: 400;
    }
  }
  .hintly-checkbox.opposite-state {
    .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
      background-color: color(red, base)!important;
      border-color: color(red, base)!important;
      color: color(white, base)!important;
    }
    .mdc-checkbox__mixedmark {
      transform: scaleX(0.9) rotate(45deg)!important;
    }
    .mdc-checkbox__native-control:indeterminate~.mdc-checkbox__background .mdc-checkbox__mixedmark, .mdc-checkbox__native-control[data-indeterminate=true]~.mdc-checkbox__background .mdc-checkbox__mixedmark {
      position: relative!important;
      &:after {
        content: '';
        width: 2px;
        height: 15px;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
      }
    }
  }
  .chevron {
    cursor: pointer;
    height: 17px;
    width: 17px;
    display: inline-flex;
    margin-bottom: -5px;
  }
  .chevron-right {
    background: url('assets/images/icons/arrow-right.svg') center center no-repeat;
  }
  .chevron-down {
    background: url('assets/images/icons/arrow-down.png') center center no-repeat;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: color(primary, base)!important;
    background-color: color(primary, base)!important;
  }
  .mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
    display: none!important;
  }
  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: color(grey, light);
    background-color: transparent;
  }
  .mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic
  {
    stroke: color(primary, darker);
  }

  // input
  .mdc-text-field {
    background-color: #fff!important; // Set custom background color
    box-shadow: none; // Remove any unwanted shadow
    border-radius: 4px!important; // Optional: Add border-radius if desired
  }

  ::-moz-selection { color: color(white, base);  background: color(primary, base); }  
  ::selection { color: color(white, base); background: color(primary, base); }
}
.btn-primary {
  background-color: color(primary, base)!important;
  border-color: color(primary, base)!important;
  border-radius: 50px!important;
  &:hover, &:active {
    background-color: color(primary, darker)!important;
    border-color: color(primary, darker)!important;
  }
  &:disabled {
    background-color: color(grey, 400)!important;
    border-color: color(grey, 400)!important;
    cursor: not-allowed!important;
  }
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem #eee!important;
  }
}
.btn-secondary {
  background-color: color(white, base)!important;
  border-color: color(grey, 400)!important;
  border-radius: 50px!important;
  color: color(black, secondary)!important;
  &:hover, &:active {
    background-color: color(grey, base)!important;
  }
  &:disabled {
    background-color: color(grey, 400)!important;
    border-color: color(grey, 400)!important;
    cursor: not-allowed!important;
  }
  &:focus-visible {
    box-shadow: 0 0 0 0.25rem #eee!important;
  }
}
