// color palette

$palettes: (
    grey: (
      base: #F5F8FB,
      light: #EAECF0,
      400: #BDBEBF,
      darker: #667085,
    ),
    primary: (
      base: #6AD7BC,
      darker: #53B69D,
    ),
    secondary: (
      base: #0D2336,
      darker: #03192c,
    ),
    red: (
      base: #E05C5C,
    ),
    white: (
      base: #FFFFFF,
    ),
    black: (
      base: #000000,
      secondary:rgb(33, 37, 41)
    )
);

@function color($color, $tone){
  @if map-has-key($palettes, $color){
      $color: map-get($palettes, $color);
          @if map-has-key($color, $tone){
              @return map-get($color, $tone);
          }

          @warn "Shade `#{$tone}` not found in color";
          @return null;
  }

  @warn "Color `#{$color}` not found in palette";
  @return null;
}

// fonts

@font-face {
  font-family: 'VisbyCF';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../assets/fonts/VisbyCFRegular-1.eot');
  src: url('../assets/fonts/VisbyCFRegular-1.eot') format('embedded-opentype'),
       url('../assets/fonts/VisbyCFRegular-23.woff2') format('woff2'),
       url('../assets/fonts/VisbyCFRegular-1.woff') format('woff'),
       url('../assets/fonts/VisbyCFRegular-1.ttf') format('truetype')
}

@font-face {
  font-family: 'VisbyCF';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../assets/fonts/VisbyCFMedium-1.eot');
  src: url('../assets/fonts/VisbyCFMedium-1.eot') format('embedded-opentype'),
       url('../assets/fonts/VisbyCFMedium-11.woff2') format('woff2'),
       url('../assets/fonts/VisbyCFMedium-1.woff') format('woff'),
       url('../assets/fonts/VisbyCFMedium-1.ttf') format('truetype')
}

@font-face {
  font-family: 'VisbyCF';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../assets/fonts/VisbyCFBold-1.eot');
  src: url('../assets/fonts/VisbyCFBold-1.eot') format('embedded-opentype'),
       url('../assets/fonts/VisbyCFBold-11.woff2') format('woff2'),
       url('../assets/fonts/VisbyCFBold-1.woff') format('woff'),
       url('../assets/fonts/VisbyCFBold-1.ttf') format('truetype')
}

@font-face {
  font-family: 'VisbyCF';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../assets/fonts/VisbyCFExtraBold-1.eot');
  src: url('../assets/fonts/VisbyCFExtraBold-1.eot') format('embedded-opentype'),
       url('../assets/fonts/VisbyCFExtraBold-11.woff2') format('woff2'),
       url('../assets/fonts/VisbyCFExtraBold-1.woff') format('woff'),
       url('../assets/fonts/VisbyCFExtraBold-1.ttf') format('truetype')
}

@font-face {
  font-family: 'VisbyCF';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('../assets/fonts/VisbyCFHeavy-1.eot');
  src: url('../assets/fonts/VisbyCFHeavy-1.eot') format('embedded-opentype'),
       url('../assets/fonts/VisbyCFHeavy-11.woff2') format('woff2'),
       url('../assets/fonts/VisbyCFHeavy-1.woff') format('woff'),
       url('../assets/fonts/VisbyCFHeavy-1.ttf') format('truetype')
}

@font-face {
  font-family: 'VisbyCF';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../assets/fonts/VisbyCFLight-1.eot');
  src: url('../assets/fonts/VisbyCFLight-1.eot') format('embedded-opentype'),
       url('../assets/fonts/VisbyCFLight-11.woff2') format('woff2'),
       url('../assets/fonts/VisbyCFLight-1.woff') format('woff'),
       url('../assets/fonts/VisbyCFLight-1.ttf') format('truetype')
}
